import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {virtualstorLogo} from '../brandsimports'
const Footer = () => {
    const company = {
        name: "VIRTUALSTOR Robert Solarski",
        taxId: "673-174-31-38",
        regon: "362835276",
        street: "ul. Kościuszki 19/6",
        postalCode: "78-400",
        city: "Szczecinek",
        mail: "info@virtualstor.pl",
        phone: "+48 536 843 461"
    
      }
    return (
        <footer className="relative bg-gray-300 pt-8 pb-6" id="contact">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">
              Skontaktuj się z nami!
            </h4>

            <div className="mt-6">
                <img className="h-20" src={virtualstorLogo}/>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-6/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                {company.name}
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                      NIP: {company.taxId} REGON: {company.regon}
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                    {company.street}, {company.postalCode} {company.city}
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                      <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>   {company.mail}
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                      <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> {company.phone}
                    </a>
                  </li>

                </ul>
              </div>

            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "} Virstualstor Robert Solarski
            </div>
          </div>
        </div>
      </div>
    </footer>
    );
};

export default Footer;