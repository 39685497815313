import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const SectorItem = (props) => {
    return (
        <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
            </div>
            <h6 className="text-xl mt-5 font-semibold text-white">
                {props.title}
            </h6>
        </div>
    );
};

export default SectorItem;