import React from 'react';
import { Link } from 'react-scroll';



const NavbarItem = ({ href, name, handleAction }) => {
    const durationScroll = 500;
    const offsetScroll = -200;
    return (
        <li>
            <Link to={href} spy={true} smooth={true} offset={offsetScroll} duration={durationScroll} onClick={handleAction}>
                <a 
                className="block 
                py-2 
                pr-4 
                pl-3 
                text-white
                rounded 
                font-bold
                md:hover:bg-transparent 
                md:border-0 
                hover:text-emerald-500
                md:p-0 
                transition-all
                ease-in-out
                duration-500"
                href="#">
                    {name}
                </a>
            </Link>
        </li>
    );
};

export default NavbarItem;