import './App.css';
import Navbar from './components/Navbar';
import Header from './containers/Header';
import AboutUs from './containers/AboutUs';
import Offer from './containers/Offer';
import Products from './containers/Products';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Header></Header>
      <AboutUs></AboutUs>
      <Offer></Offer>
      <Products></Products>
      <Footer></Footer>
    </div>
  );
}

export default App;
