import React from 'react';
import { bitdefender, fortinet, hp, qnap, dell, synology, netapp, sophos } from '../brandsimports';
const Brands = () => {
    const brandsList = [bitdefender, fortinet, hp, qnap, dell, synology, netapp, sophos];
    return (
        <div className='
        md:mt-20
        mt-10
        flex

        items-center
        justify-center
        w-full
        flex-wrap'>
            {brandsList.map((brand, index) => 
                <div className='flex max-h-20 justify-center p-5'>
                    <img className='sm:max-h-10 max-h-6 brightness-0 invert' src={brand}></img>
                </div>
            )}            
        </div>
    );
};

export default Brands;