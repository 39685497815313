import React from 'react';
import AboutUsCard from '../components/AboutUsCard';
import { faComputer, faServer, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import EMCImage from '../assets/dell-emc.jpeg';
import DellGoldPartner from '../assets/DT_GoldPartner_4C.png';

const AboutUs = () => {
  const serversCard = {
    title: 'Serwery i macierze',
    text: 'Nasza oferta obejmuje najnowsze modele serwerów i macierzy o     wysokiej wydajności i niezawodności, dopasowane do różnych potrzeb biznesowych. Dzięki naszym rozwiązaniom serwerowym Twoja firma może liczyć na zwiększenie wydajności pracy i ochronę cennych danych.',
    icon: faServer
  }

  const desktopCard = {
    title: 'Komputery i peryferia',
    text: 'W naszej ofercie znajdziesz wydajne i niezawodne komputery stacjonarne oraz laptopy, a także urządzenia peryferyjne, takie jak drukarki czy skanery. Nasze rozwiązania pozwolą Twoim pracownikom na sprawną i efektywną pracę, a Twoja firma zyska na wydajności i bezpieczeństwie danych.',
    icon: faComputer
  }

  const networkCard = {
    title: 'Urządzenia sieciowe i bezpieczeństwo IT',
    text: "Oferujemy rozwiązania sieciowe, takie jak routery, switche, punkty dostępowe oraz rozwiązania VPN. Dla zapewnienia bezpieczeństwa Twoich danych oferujemy oprogramowanie antywirusowe, rozwiązania zabezpieczające przed atakami DDoS, a także firewall'e i systemy IPS/IDS.",
    icon: faNetworkWired
  }
    return (
        <section className="pb-20 bg-gray-300 -mt-24" id="about_us">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <AboutUsCard isLower={true} {...serversCard}/>
              <AboutUsCard {...desktopCard}/>
              <AboutUsCard isLower={true} {...networkCard}/>

            </div>


            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">

                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  O nas
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Jako złoty partner Della, VirtualStor oferuje niestandardowe rozwiązania IT, dostosowane do potrzeb Twojej firmy. Nasza misja to umożliwienie Twojemu zespołowi osiągania sukcesów, zamiast stawiania przeszkód w postaci problemów technologicznych. Dlatego oferujemy szeroką gamę usług, w tym projektowanie, wdrożenia oraz wsparcie techniczne, aby Twoja firma mogła działać sprawnie i skutecznie. Nasza praca jest nieustannie kierowana ku doskonałości, ponieważ wiemy, że Twoje sukcesy są naszym sukcesem.
                </p>

              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg bg-white">
                  <img
                    alt="..."
                    src={EMCImage}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-yellow-500 fill-current"
                      ></polygon>
                    </svg>
                    <img alt="Dell Gold Partner"src={DellGoldPartner}></img>
                  </blockquote>
                </div>
              </div>

            </div>
          </div>
        </section>
    );
};

export default AboutUs;