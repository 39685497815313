import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const OfferPoint = (props) => {
    return (
        <li className="py-2">
            <div className="flex items-center">
            <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-100 bg-emerald-600 mr-3">
                <FontAwesomeIcon icon={props.icon}> </FontAwesomeIcon>
                </span>
            </div>
            <div>
                <h4 className="text-gray-600">
                {props.text}
                </h4>
            </div>
            </div>
        </li>
    );
};

export default OfferPoint;