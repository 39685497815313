import React from 'react';
import Brands from '../components/Brands';
import { faSchool, faBuildingColumns, faIndustry  } from '@fortawesome/free-solid-svg-icons';
import SectorItem from '../components/SectorItem';
const Products = () => {
    const edu = {
        title: "Edukacja",
        icon: faSchool
    }
    const company = {
        title: "Sektor prywatny",
        icon: faIndustry
    }
    const gov = {
        title: "Sektor publiczny",
        icon: faBuildingColumns
    }
    const sectors = [edu, gov, company]
    return (
        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-10">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Produkty
                </h2>

                
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                Jesteśmy przekonani, że nasze produkty spełnią Państwa oczekiwania, ponieważ zawsze dokładnie dopasowujemy je do specyfiki Państwa firmy i potrzeb biznesowych. Wszystkie nasze rozwiązania są starannie przetestowane i sprawdzone, dzięki czemu możemy zagwarantować ich niezawodność i wysoką jakość. 
                </p>
              </div>
            </div>
            
            <div className="flex flex-wrap mt-12 justify-center">
                {sectors.map((props) =>
                    <SectorItem {...props}></SectorItem>
                )}
            </div>
            <Brands></Brands>
          </div>
        </section>
    );
};

export default Products;