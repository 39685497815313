import React from "react";
import Logo from '../assets/virtualstor-concept-white-green.png';
import NavbarItem from "./NavbarItem";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const bodyEl = document.querySelector("body");
  const menuToggle = (action) => {
    (action) ?
      bodyEl.style.overflow = "hidden"  :
      bodyEl.style.overflow = "auto";
    setNavbarOpen(action);
  }
  return (
    <nav
      className=
        "top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 "
      
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between ">
        <div className="flex items-center">
          <img className="mr-3 h-9" src={Logo}></img>
        </div>
        <button className="md:hidden z-50" onClick={()=>menuToggle(!navbarOpen)}>
          <FontAwesomeIcon className="text-white text-2xl"icon={!navbarOpen ? faBars : faClose}></FontAwesomeIcon>
        </button>
        <ul  className={(navbarOpen === true ? "flex" : "hidden") + 
        " z-40 lg:w-1/4 md:w-1/3 md:h-auto md:relative md:inline-flex md:min-h-0 md:min-w-0  md:justify-between md:bg-transparent md:flex-row transition-all ease-in-out duration-300 min-w-full bg-black min-h-screen block text-center absolute top-0 left-0 justify-center flex-col "}>
        <p className="text-white">{navbarOpen}</p>
          <NavbarItem handleAction={()=>menuToggle(false)} href={"home"} name={"Start"}/>
          <NavbarItem handleAction={()=>menuToggle(false)} href={"about_us"} name={"O nas"}/>
          <NavbarItem handleAction={()=>menuToggle(false)} href={"offer"} name={"Oferta"}/>
          <NavbarItem handleAction={()=>menuToggle(false)} href={"contact"} name={"Kontakt"}/>
        </ul>
      </div>
    </nav>
  );
}
