import React from 'react';
import OfferPoint from '../components/OfferPoint';
import { faListCheck, faBook, faWrench } from '@fortawesome/free-solid-svg-icons';
import ServerImage from '../assets/servers3.jpeg';

const Offer = () => {
    const integrationPoint = {
        icon: faWrench,
        text: "Wdrożenia"
    }
    const projectPoint = {
        icon: faListCheck,
        text: "Projektowanie"
    }
    const documentationPoint = {
        icon: faBook,
        text: "Twożenie dokumentacji"
    }

    const points = [projectPoint, integrationPoint, documentationPoint];
    return (
        <section className="relative py-20" id="offer">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={ServerImage}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
 
                  <h3 className="text-3xl font-semibold">
                    Oferta
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Nasza oferta to nie tylko standardowe rozwiązania IT, ale także najnowsze technologie, które pomogą Twojej firmie optymalizować procesy. Jesteśmy dumni z referencji w realizacji dużych projektów dla instytucji publicznych oraz firm, co potwierdza nasze doświadczenie i profesjonalizm. Dzięki temu możesz mieć pewność, że z nami Twoja firma będzie działała na najwyższym poziomie, wykorzystując zaawansowane technologie i najlepsze praktyki branżowe.
                  </p>
                  <ul className="list-none mt-6">
                    {points.map((el) => 
                        <OfferPoint {...el}></OfferPoint>
                    )}
                    
                
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
};

export default Offer;